// Here you can add other styles

// .form-container {
//     position: relative;
// }

// .form-header-image {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
// }

.custom-placeholder::placeholder {
    color: white; /* You can change this to any color */
  }

  .custom-top-right {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
  }
  